<template>
  <div>
    <vca-field :label="$t('users.edit.general.header')">
      <vca-card class="shadowed dashlet-content">
        <h3>{{ $t("users.edit.general.name.label") }}</h3>
        <vca-row>
          <div class="vertical-center">
            <span class="bold">{{ current.display_name }}</span>
            ({{ current.first_name }} {{ current.last_name }})
          </div>
        </vca-row>
        <h3>{{ $t("users.edit.general.email.label") }}</h3>
        <vca-row>
          <div class="vertical-center">
            <span class="bold">{{ current.email }}</span>
          </div>
        </vca-row>
      </vca-card>
      <div>
        <h3>{{ $t("users.edit.general.phone.label") }}</h3>
        <vca-input
          v-model="current.profile.phone"
          :errorMsg="$t('users.edit.general.phone.errorMsg')"
          :maxLength="14"
          type="tel"
          ref="phone"
          :placeholder="$t('users.edit.general.phone.placeholder')"
        />
      </div>
      <div>
        <h3>{{ $t("users.edit.general.mattermost.label") }}</h3>
        <vca-input
          v-model="current.profile.mattermost_username"
          :placeholder="$t('users.edit.general.mattermost.placeholder')"
        />
      </div>
      <div>
        <h3>{{ $t("users.edit.general.birthdate.label") }}</h3>
        <vca-row>
          <vca-input-date
            v-model="current.profile.birthdate"
            :default="openDate"
            :disabledValues="disabledValues"
            ref="birthdate"
            :language="this.$i18n.locale"
            :placeholder="$t('users.edit.general.birthdate.placeholder')"
          />
          <div
            v-if="current.profile.birthdate"
            class="small-cancel-button input-cancel-button"
          >
            <vca-cancel-button @click="resetDate()" />
          </div>
        </vca-row>
      </div>
      <div>
        <h3>{{ $t("users.edit.general.gender.label") }}</h3>
        <vca-row>
          <div>
            <input
              type="radio"
              v-model="current.profile.gender"
              id="female"
              value="female"
            />
            <label for="female">
              {{ $t("users.edit.general.gender.female") }}</label
            >
          </div>
          <div>
            <input
              type="radio"
              v-model="current.profile.gender"
              id="male"
              value="male"
            />
            <label for="male">
              {{ $t("users.edit.general.gender.male") }}</label
            >
          </div>
          <div>
            <input
              type="radio"
              v-model="current.profile.gender"
              id="divers"
              value="divers"
            />
            <label for="divers">
              {{ $t("users.edit.general.gender.divers") }}</label
            >
          </div>
          <div>
            <input
              type="radio"
              v-model="current.profile.gender"
              id="none"
              value=""
            />
            <label for="none">
              {{ $t("users.edit.general.gender.none") }}</label
            >
          </div>
        </vca-row>
      </div>
      <button class="vca-button" @click="update()">
        {{ $t("button.save") }}
      </button>
    </vca-field>
  </div>
</template>

<script>
export default {
  name: "ProfileGeneral",
  computed: {
    current: {
      set(value) {
        this.$store.commit("users/current", value);
      },
      get() {
        return this.$store.state.users.current;
      },
    },
    openDate() {
      return new Date().setFullYear(new Date().getFullYear() - 16) / 1000;
    },
    disabledValues() {
      return {
        from: new Date(),
      };
    },
  },
  methods: {
    update() {
      this.$store.dispatch({ type: "users/update_profile" }).then(() => {
        this.$store.dispatch({
          type: "users/getById",
          data: this.current.id,
        });
      });
    },
    resetDate() {
      if (!confirm(this.$t("users.edit.birthdate.delete.confirm"))) {
        return false;
      }
      this.$refs.birthdate.clearDate();
    },
  },
};
</script>
<style lang="scss">
.input-cancel-button {
  margin: 0 1em 1em;

  .cancel-button {
    height: 48px;
  }
}
</style>
