<template>
  <vca-field :label="$t('users.edit.crew.header')">
    <vca-column>
      <h3>{{ $t("profile.edit.crew.select.label") }}</h3>
      <vca-dropdown
        v-model="select"
        :options="crewList"
        label=""
        :placeholder="$t('users.edit.crew.select.placeholder')"
      ></vca-dropdown>
      <button
        v-if="!hasCrew"
        class="vca-button"
        :disabled="select.length == 0"
        @click="create()"
      >
        {{ $t("button.enter") }}
      </button>
      <div v-else>
        <button class="vca-button" @click="leave()" v-if="select.length == 0">
          {{ $t("button.change") }}
        </button>
        <button
          class="vca-button"
          @click="update()"
          v-else
          :disabled="select[0].value == current.crew.crew_id"
        >
          {{ $t("button.change") }}
        </button>
        <div class="vca-center">
          <vca-cancel-button
            :placeholder="
              $t('context_button.remove', {
                0: $t('profile.edit.crew.context'),
              })
            "
            @click="leave()"
          />
        </div>
      </div>
    </vca-column>
  </vca-field>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ProfileCrew",
  data() {
    return { crew_id: null };
  },
  computed: {
    select: {
      set(value) {
        if (value.length > 0) {
          this.crew_id = value[0].value;
        } else {
          this.crew_id = "";
        }
      },
      get() {
        return [{ value: this.crew_id }];
      },
    },
    ...mapGetters({
      crewList: "crews/dropdown",
      current: "users/current",
    }),
    hasCrew() {
      return this.current.crew.id != "";
    },
  },
  methods: {
    create() {
      this.$store
        .dispatch({
          type: "users/create_crew",
          data: { crew_id: this.crew_id },
        })
        .then(() => {
          this.$store.dispatch({
            type: "users/getById",
            data: this.current.id,
          });
        });
    },
    update() {
      if (!confirm(this.$t("users.edit.crew.select.confirm"))) {
        return false;
      }

      if (this.select.length == 0) {
        this.$store.dispatch({ type: "users/delete_crew" }).then(() => {
          this.$store.dispatch({
            type: "users/getById",
            data: this.current.id,
          });
        });
      } else {
        this.$store.dispatch({ type: "users/delete_crew" }).then(() => {
          this.create();
        });
      }
    },
    leave() {
      if (!confirm(this.$t("users.edit.crew.select.confirm"))) {
        return false;
      }
      this.$store.dispatch({ type: "users/delete_crew" }).then(() => {
        this.select = [];
        this.$store.dispatch({
          type: "users/getById",
          data: this.current.id,
        });
      });
    },
  },
  created() {
    this.$store
      .dispatch({ type: "crews/public" })
      .then(() => {
        this.crew_id = this.current.crew.crew_id;
      })
      .catch();
  },
};
</script>
