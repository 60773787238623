<template>
  <div>
    <h3>{{ $t("users.newsletter.header") }}</h3>
    <vca-row>
      <vca-checkbox
        :disabled="current.crew.id == ''"
        v-model="newsletter"
        @input="process('regional')"
        id="regional"
      >
        {{ $t("profile.newsletter.regional.title") }}
      </vca-checkbox>
      <vca-checkbox v-model="newsletter" @input="process('global')" id="global">
        {{ $t("profile.newsletter.global.title") }}
      </vca-checkbox>
    </vca-row>
  </div>
</template>

<script>
export default {
  name: "NewsletterSelection",
  computed: {
    current: {
      get() {
        return this.$store.state.users.current;
      },
      set(value) {
        this.$store.commit("users/current", value);
      },
    },
    newsletter: {
      get() {
        return this.current.newsletter.map((el) => {
          return el.value;
        }); //this.$store.state.users.current.newsletter
      },
      set(value) {
        return value;
      },
    },
    list: {
      get() {
        return this.$store.state.user.newsletter.list.filter((el) => {
          el.title = this.$t(el.title);
          el.subtitle = this.$t(el.subtitle);
          el.label = this.$t(el.label);
          return true;
        });
      },
    },
  },
  methods: {
    process(value) {
      const newsletter = this.current.newsletter.find(
        (val) => val.value == value
      );
      if (newsletter != undefined) {
        this.$store
          .dispatch({
            type: "user/newsletter/delete",
            data: {
              id: newsletter.id,
              value,
            },
          })
          .then(() => {
            this.current.newsletter = this.current.newsletter.filter((el) => {
              return el.value != value;
            });
            this.$store.dispatch("refresh");
          });
      } else {
        this.$store
          .dispatch({
            type: "user/newsletter/create",
            data: {
              user_id: this.current.id,
              value: value,
            },
          })
          .then((response) => {
            this.$store.state.users.current.newsletter.push(response);
            this.$store.dispatch("refresh");
          });
      }
    },
  },
};
</script>
