<template>
  <vca-card>
    <ProfileHeader />
    <vca-row>
      <vca-card class="shadowed">
        <UsersGeneral />
        <UsersNewsletter />
      </vca-card>
      <vca-card class="shadowed">
        <UsersCrew />
        <UsersAddress />
      </vca-card>
    </vca-row>
  </vca-card>
</template>

<script>
import UsersGeneral from "./UsersGeneral.vue";
import UsersNewsletter from "./UsersNewsletter.vue";
import UsersCrew from "./UsersCrew.vue";
import UsersAddress from "./UsersAddress.vue";
import ProfileHeader from "../profile/ProfileHeader.vue";

export default {
  name: "UserDisplay",
  components: {
    UsersGeneral,
    UsersNewsletter,
    UsersCrew,
    UsersAddress,
    ProfileHeader,
  },
};
</script>
